var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid text-left py-4"},[_vm._m(0),_c('hr',{staticClass:"my-0"}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[(_vm.$store.state.isMobile)?_c('b-dropdown',{attrs:{"block":"","variant":"primary","text":_vm.$route.name,"menu-class":"w-100","size":"lg"}},_vm._l((_vm.menu),function(mn){return _c('b-dropdown-item',{key:`menuest2_${mn.name}`,staticStyle:{"height":"50px","width":"100%","text-align":"center"},attrs:{"to":mn.link}},[_c('span',{domProps:{"innerHTML":_vm._s(mn.icon)}}),_vm._v(" "+_vm._s(mn.name)+" "),_c('hr')])}),1):_vm._e(),(
          (!_vm.$store.state.isMobile &&
            _vm.$store.state.auth.user.tipo == 'Administrador') ||
          _vm.$store.state.auth.user.tipo == 'Gerente' ||
          _vm.$store.state.auth.user.tipo == 'Contador' 
        )?_c('div',{staticClass:"menuP"},[_vm._l((_vm.menu),function(mn){return _c('router-link',{key:`menuest_${mn.name}`,staticClass:"m cursor text-center",attrs:{"to":mn.link,"active-class":"active"}},[_c('span',{domProps:{"innerHTML":_vm._s(mn.icon)}}),_vm._v(" "+_vm._s(mn.name)+" ")])}),_c('div',{staticStyle:{"flex":"1"}}),_c('div',{staticStyle:{"padding-top":"3px"}},[_c('EmpresaCombo',{attrs:{"comTodos":"","selected":_vm.empresa_selecionada.cod_empresa,"selecionar":_vm.selectEmpresa}})],1)],2):_vm._e()],1)]),_c('router-view',{attrs:{"empresa":_vm.empresa_selecionada,"empresaSelecionada":_vm.empresa_selecionada}})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('h2',[_c('i',{staticClass:"fa fa-dollar-sign mr-1"}),_vm._v("Área Fiscal")])
}]

export { render, staticRenderFns }