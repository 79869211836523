<template>
  <div class="container-fluid text-left py-4">
    <h2><i class="fa fa-dollar-sign mr-1"></i>Área Fiscal</h2>
    <hr class="my-0" />
    <div class="row">
      <div class="col-12">
        <b-dropdown
          block
          variant="primary"
          :text="$route.name"
          v-if="$store.state.isMobile"
          menu-class="w-100"
          size="lg"
        >
          <b-dropdown-item
            :to="mn.link"
            v-for="mn in menu"
            :key="`menuest2_${mn.name}`"
            style="height: 50px; width: 100%; text-align: center"
          >
            <span v-html="mn.icon"></span>
            {{ mn.name }}
            <hr />
          </b-dropdown-item>
        </b-dropdown>
   
        <div
          class="menuP"
          v-if="
            (!$store.state.isMobile &&
              $store.state.auth.user.tipo == 'Administrador') ||
            $store.state.auth.user.tipo == 'Gerente' ||
            $store.state.auth.user.tipo == 'Contador' 
          "
        >
          <router-link
            v-for="mn in menu"
            :key="`menuest_${mn.name}`"
            class="m cursor text-center"
            :to="mn.link"
            active-class="active"
          >
            <span v-html="mn.icon"></span>
            {{ mn.name }}
          </router-link>
          <div style="flex: 1"></div>
          <div class="" style="padding-top:3px">
            <EmpresaCombo
              comTodos
              :selected="empresa_selecionada.cod_empresa"
              :selecionar="selectEmpresa"
            ></EmpresaCombo>
          </div>
        </div>
      </div>
    </div>
  
    <router-view :empresa="empresa_selecionada" :empresaSelecionada="empresa_selecionada"></router-view>
  </div>
</template>

<script>
import EmpresaCombo from "../../components/empresa/EmpresaCombo.vue";
export default {
  components: {
    EmpresaCombo,
  },
  props: {},
  mounted() {},
  data() {
    return {
      empresa_selecionada: { "cod_empresa": -1, "nome": "TODOS" },
      menu: [
        {
          link: "/fiscal/home",
          icon: `<i class="fa fa-shop"/> `,
          name: "Início",
        },
        {
          link: "/fiscal/notas",
          icon: `<i class="fa fa-file" />`,
          name: "Notas Fiscais",
        },
        {
          link: "/fiscal/configuracoes",
          icon: `<i class="fa fa-cogs" />`,
          name: " Configurações",
        },
        {
          link: "/fiscal/baixar_xml",
          icon: `<i class="fa fa-download" />`,
          name: " Baixar XML Lote",
        },

        {
          link: "/fiscal/inutilizacao",
          icon: `<i class="fas fa-ban" />`,
          name: " Inutilização",
        },
      ],
    };
  },
  watch: {},
  computed: {},
  methods: {
    selectEmpresa(empresa) {
      this.empresa_selecionada = empresa;
    },
  },
};
</script>

<style lang="scss" scoped></style>
